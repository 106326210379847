import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import { withRouter } from "react-router-dom";
import {userSignOut} from "../../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";


class UserProfile extends Component {
  render() {
    const {authUser} = this.props;
    if(!authUser) {
      return null;
    }

    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => {this.props.history.push(`/user/me`)}}><IntlMessages id="app.userAuth.myProfile"/></li>
        {/* <li><IntlMessages id="app.userAuth.connection"/></li> */}
        <li onClick={() => this.props.userSignOut()}><IntlMessages id="app.userAuth.logout"/></li>
      </ul>
    );
    const avatar = authUser.avatar || 'https://wieldy.g-axon.work/static/media/garry-sobars.aa4d3b15.png';

    return (

      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          <Avatar src={avatar} className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
          <span className="gx-avatar-name">
            {authUser.firstName} {authUser.lastName}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/>
          </span>
        </Popover>
      </div>

    )

  }
}

const mapStateToProps = ({auth, settings}) => {
  const {authUser} = auth;
  const {locale} = settings;
  return {authUser, locale}
};

export default connect(mapStateToProps, {userSignOut})(withRouter(UserProfile));
