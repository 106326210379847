const helper = {
  getErrorMessageApi:(error)=>{
    if(error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.message)
      return error.response.data.errors.message;
    if(error && error.response && error.response.data && error.response.data.errors)
      return error.response.data.errors;
    if(error && error.response && error.response.data)
      return error.response.data;
    if(error && error.message)
      return error.message;
    return error
  }
}

module.exports = helper;