/* eslint-disable */
import React from "react";
import {Button, message} from "antd";
import IntlMessages from "util/IntlMessages";
import {Link, Redirect} from "react-router-dom";
import queryString from 'query-string';
import {verify, resendEmail} from "../appRedux/actions/User";
import {connect} from "react-redux";
// import {userSignIn} from "../appRedux/actions";

class VerifyUser extends React.Component {

  constructor(props) {
    super(props);
    const query = this.props.location.search;
    const param = queryString.parse(query);

    this.state = {
      email: param.email,
      token: param.token,
      submitting: false,
    }

    this.verify = verify.bind(this);
    this.resendEmail = resendEmail.bind(this);
  }

  componentDidMount() {
    if (this.state.token) {
      this.handleVerify();
    }
  }

  handleVerify = () => {
    this.verify({
      token: this.state.token,
    }).then((results) => {
      message.success('Account verify successfully.');
      setTimeout(()=>{
        this.props.history.push('/signin');
      },1000);
    })
  };

  onResendEmail = () => {
    const { submitting } = this.state;

    if(submitting) {
      return;
    }
    this.setState({
      submitting: true,
    })
    this.resendEmail({
      email: this.state.email,
    }).then((results) => {
      message.success('Email sent successfully');
      this.setState({
        submitting: false,
      })
    }, error => {
      message.error('Failed to resend email, with error code: ' + error.message);
      this.setState({
        submitting: false,
      })
    })
  };

  render() {
    const { email, token, submitting } = this.state;
    const loggedToken  = this.props.token;
    if(loggedToken) {
      return ( <Redirect to={'/dashboard'}/> );
    }
    return (
      <div className="gx-login-container fix-height">
        <div className="gx-login-content gx-text-center">
          <div className="gx-login-header">
            <img alt="" src={require("assets/images/logo.png")}/>
          </div>
          <div className="gx-mb-4 text-center">
            <h3>Verify your email</h3>
            <p>You will need to verify your email to complete registration.</p>
            
            {email && <div>
              <div style={{fontSize: 200}}><i className="icon icon-mail-open"/></div>
              <p>An email have been sent to <b>{email}</b> with a link to verify your account. If you have not received the email
                after a few minutes, please check your spam folder.</p>
              <Button disabled={submitting} type="primary" htmlType="button" onClick={this.onResendEmail}>Resend Email</Button>
              <span><IntlMessages id="app.userAuth.or"/> </span> 
              <Link to="/signin"><IntlMessages id="app.userAuth.signIn"/></Link>
            </div>}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps)(VerifyUser);
