/* eslint-disable */
import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, NOTIFY_ERROR_MESSAGE,NOTIFY_HIDE_MESSAGE,NOTIFY_SUCCESS_MESSAGE,NOTIFY_WARNING_MESSAGE,NOTIFY_INFO_MESSAGE, SHOW_MESSAGE, LOADING_START, LOADING_STOP} from '../../constants/ActionTypes'
import * as _ from "lodash"
const INIT_STATE = {
  loading: false,
  errorMessage:'',
  successMessage:'',
  warningMessage:'',
  infoMessage:'',
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING_START: {
      return {...state, 
        loading: true, 
        errorMessage:'',
        successMessage:'',
        warningMessage:'',
        infoMessage:'',
      };
    }
    case LOADING_STOP: {
      return {
        ...state, 
        loading: false, 
        errorMessage:'',
        successMessage:'',
        warningMessage:'',
        infoMessage:'',
      };
    }
    case NOTIFY_ERROR_MESSAGE: {
      return {
        ...state, 
        loading: false, 
        errorMessage: action.payload,
        successMessage:'',
        warningMessage:'',
        infoMessage:'',
      }
    }
    case NOTIFY_SUCCESS_MESSAGE: {
      return {
        ...state, 
        loading: false, 
        errorMessage:'',
        successMessage: action.payload,
        warningMessage:'',
        infoMessage:'',
      }
    }
    case NOTIFY_WARNING_MESSAGE: {
      return {
        ...state, 
        loading: false, 
        errorMessage:'',
        successMessage: '',
        warningMessage: action.payload,
        infoMessage:'', 
      }
    }
    case NOTIFY_INFO_MESSAGE: {
      return {
        ...state, 
        loading: false, 
        errorMessage:'',
        successMessage: '',
        warningMessage: '',
        infoMessage: action.payload, 
      }
    }
    case NOTIFY_HIDE_MESSAGE: {
      return {
        ...state, 
        loading: false, 
        errorMessage:'',
        successMessage: '',
        warningMessage: '',
        infoMessage: '', 
      }
    }
    default:
      return state;
  }
}
