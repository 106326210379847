/* eslint-disable */
import React from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {userSignUp, socialLogin} from "../appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import SocialLogin from "../components/Social/SocialLogin";

const FormItem = Form.Item;

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      success: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {submitting} = this.state;
    this.props.form.validateFields((err, values) => {
      if (!err && !submitting) {
        this.setState({
          submitting: true,
        })
        this.props.userSignUp(values, () => {
          this.props.history.push(
            {
              pathname: '/verify',
              search: `?email=${values.email}`
            });
        }, () => {
          this.setState({
            submitting: false,
          })
        });
      }
    });
  };

  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                {/*<img src='https://via.placeholder.com/272x395' alt='Neature'/>*/}
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.signUp"/></h1>
                <p><IntlMessages id="app.userAuth.bySigning"/></p>
                <p><IntlMessages id="app.userAuth.getAccount"/></p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo-h.png")}/>
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('firstName', {
                    rules: [{required: true, message: 'Please input your firstname!'}],
                  })(
                    <Input placeholder="Firstname"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('lastName', {
                    rules: [{required: true, message: 'Please input your firstname!'}],
                  })(
                    <Input placeholder="Lastname"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('username', {
                    rules: [{required: true, message: 'Please input your username!'}],
                  })(
                    <Input placeholder="Username"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [{
                      required: true, type: 'email', message: 'The input is not valid E-mail!',
                    }],
                  })(
                    <Input placeholder="Email"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{required: true, message: 'Please input your Password!'}],
                  })(
                    <Input type="password" placeholder="Password"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(
                    <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                  )}
                  <a href="https://dikauri.com/terms-and-conditions/" target="_blank"><span className="gx-signup-form-forgot gx-link"><IntlMessages
                    id="appModule.termAndCondition"/></span></a>
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signUp"/>
                  </Button>
                  <span><IntlMessages id="app.userAuth.or"/> </span> 
                  <Link to="/signin"><IntlMessages id="app.userAuth.signIn"/></Link>
                </FormItem>
              </Form>
              <SocialLogin />
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {userSignUp, socialLogin})(WrappedSignUpForm);
