/* eslint-disable */
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  LOADING_START,
  LOADING_STOP,
  NOTIFY_ERROR_MESSAGE,
  NOTIFY_INFO_MESSAGE,
  NOTIFY_SUCCESS_MESSAGE,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes";
import axios from '../../util/Api';
import { getErrorMessageApi } from '../../util/helper';
import { message } from "antd";
import { ROLE_ADMIN, ROLE_USER, USER_ROLES } from "../../constants/Roles";

const setToken = (dispatch, data) => {
  const accessToken = data.data.token;
  axios.defaults.headers.common['access-token'] = "Bearer " + accessToken;
  dispatch({ type: USER_TOKEN_SET, payload: accessToken });
  dispatch({ type: USER_DATA, payload: data.data });
  localStorage.setItem('token', accessToken);
  localStorage.setItem('user', JSON.stringify(data.data));
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = (params, onSuccess, onError) => {
  return (dispatch) => {
    dispatch({ type: LOADING_START });
    axios.post('api/users/create', params)
      .then(({ data }) => {
        if (data && data.data) {
          dispatch({ type: NOTIFY_SUCCESS_MESSAGE, payload: `Register successfully` });
          onSuccess();
        } else {
          dispatch({ type: NOTIFY_ERROR_MESSAGE, payload: data.error });
          onError();
        }
      }).catch(error => {
        dispatch({ type: NOTIFY_ERROR_MESSAGE, payload: getErrorMessageApi(error) });
        onError();
      });
  }
};

export const socialLogin = (params, onError) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post('api/users/social-login', params)
      .then(({ data }) => {
        if (data && data.data) {
          dispatch({ type: FETCH_SUCCESS });
          if (data.data && data.data.token) {
            setToken(dispatch, data);
          } else {
            onError();
          }
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
          onError();
        }
      }).catch(error => {
        dispatch({ type: FETCH_ERROR, payload: error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.message ? error.response.data.errors.message : error.message });
        onError();
      });
  }
};

export const updateUserInfo = (data) => {
  return (dispatch) => {
    dispatch({ type: USER_DATA, payload: data });
    localStorage.setItem('user', JSON.stringify(data))
  }
};

export const userSignIn = (obj, callback) => {
  return (dispatch) => {
    dispatch({ type: LOADING_START });
    axios.post('api/users/login', obj).then(({ data }) => {
      let user = data.data;
      if (user && user.role === ROLE_USER) {
        dispatch({ type: NOTIFY_ERROR_MESSAGE, payload: `Permission denied! Your role can not access this application.` });
      } else if (data.data && data.data.token) {
        dispatch({ type: NOTIFY_SUCCESS_MESSAGE, payload: `Login successfully` });
        setToken(dispatch, data);
      } else if (data.data) {
        if (data.data.info && !data.data.info.emailVerified) {
          dispatch({ type: NOTIFY_ERROR_MESSAGE, payload: `Your account haven't active yet.` });
          callback(data.data);
        } else if (!data.data.token) {
          dispatch({ type: NOTIFY_ERROR_MESSAGE, payload: `Login fail. Not found token` });
        }
      } else {
        dispatch({ type: NOTIFY_ERROR_MESSAGE, payload: `There are something error. Please try again.` });
      }
    }).catch((error) => {
      dispatch({ type: NOTIFY_ERROR_MESSAGE, payload: getErrorMessageApi(error) });
    });
  }
};

export const getUser = () => {
  return (dispatch) => {
    // dispatch({type: FETCH_SUCCESS});
    const authUser = JSON.parse(localStorage.getItem('user'));
    dispatch({ type: USER_DATA, payload: authUser });
  }
};


export const userSignOut = () => {
  return (dispatch) => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch({ type: SIGNOUT_USER_SUCCESS });
    dispatch({ type: NOTIFY_SUCCESS_MESSAGE, payload: `Logout successfully` });
  }
};
