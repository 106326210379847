/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import URLSearchParams from 'url-search-params';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import ReactNotification from 'react-notifications-component';
import { LocaleProvider, message } from 'antd';
import { IntlProvider } from 'react-intl';
import AppLocale from 'lngProvider';
import MainApp from './MainApp';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import VerifyUser from '../VerifyUser';
import ForgetPassword from '../ForgetPassword';
import { setInitUrl } from 'appRedux/actions/Auth';
import { getAuthUser } from 'appRedux/actions/User';
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from 'appRedux/actions/Setting';
import axios from 'util/Api';
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from '../../constants/ThemeSetting';
import { getUser } from '../../appRedux/actions/Auth';
import { googleApiKey } from '../../util/config';

const RestrictedRoute = ({ component: Component, token, ...rest }) => {
  if (!token) {
    token = localStorage.getItem('token');
  }
  if (!token) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }
  return (
    <Route
      {...rest}
      token={token}
      render={(props) =>
          <Component {...props} />
      }
    />
  );
};

class App extends Component {
  constructor(props) {
    super(props);
  }

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  setNavStyle = (navStyle) => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  componentWillMount() {
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);
    if (params.has('theme')) {
      this.props.setThemeType(params.get('theme'));
    }
    if (params.has('nav-style')) {
      this.props.onNavStyleChange(params.get('nav-style'));
    }
    if (params.has('layout-type')) {
      this.props.onLayoutTypeChange(params.get('layout-type'));
    }
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places,geometry,drawing&key=${googleApiKey}`;
    script.async = true;
    script.defer = true;
    script.addEventListener('load', () => {
      console.log(`%c load gg maps key ok`, 'background: green; font-size:14px; padding:10px;color:white;');
    });
    document.body.appendChild(script);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + nextProps.token;
    }
    if (nextProps.token && !nextProps.authUser) {
      this.props.getUser();
    }

    const { errorMessage, successMessage, warningMessage, infoMessage } = nextProps;
    if (nextProps && nextProps.errorMessage && nextProps.errorMessage !== this.props.errorMessage) {
      message.error(errorMessage);
    }
    if (nextProps && nextProps.successMessage && nextProps.successMessage !== this.props.successMessage) {
      message.success(successMessage);
    }
    if (nextProps && nextProps.warningMessage && nextProps.warningMessage !== this.props.warningMessage) {
      message.warn(warningMessage);
    }
    if (nextProps && nextProps.infoMessage && nextProps.infoMessage !== this.props.infoMessage) {
      message.info(infoMessage);
    }
  }

  render() {
    const { match, location, themeType, layoutType, navStyle, locale, initURL } = this.props;
    let { token, authUser } = this.props;
    if (!token) {
      token = localStorage.getItem('token');
    }
    if (!authUser || !authUser.id) {
      authUser = getAuthUser();
    }

    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
    }

    if (location.pathname === '/') {
      if (token === null) {
        return <Redirect to={'/signin'} />;
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return <Redirect to={'/dashboard'} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }

    if (token && location.pathname === '/signup') {
      return <Redirect to={'/dashboard'} />;
    }

    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];

    return (
      <LoadingOverlay active={false} spinner={true} text="">
        <LocaleProvider locale={currentAppLocale.antd}>
          <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
            <Switch>
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/forgot-password" component={ForgetPassword} />
              <Route exact path="/verify" component={VerifyUser} />
              <RestrictedRoute path={`${match.url}`} token={token} authUser={authUser} component={MainApp} />
            </Switch>
          </IntlProvider>
        </LocaleProvider>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = ({ settings, auth, commonData }) => {
  const { locale, navStyle, themeType, layoutType } = settings;
  const { authUser, initURL } = auth;
  let token = auth.token;
  if (!token) {
    token = localStorage.getItem('token');
  }
  const { errorMessage, successMessage, warningMessage, infoMessage, loading } = commonData;
  return { locale, token, navStyle, themeType, layoutType, authUser, initURL, loading, errorMessage, successMessage, warningMessage, infoMessage };
};
export default connect(mapStateToProps, { setInitUrl, getUser, setThemeType, onNavStyleChange, onLayoutTypeChange })(App);
