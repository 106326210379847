/* eslint-disable */
import axios from 'axios';
import {message} from "antd";
import {store} from "./../NextApp";
import {API_URL} from './config.js';

let convertObjectIdToId=(data)=>{
  if(Array.isArray(data)){
    return data.map(r=>{
      return convertObjectIdToId(r);
    })
  }else if(typeof data === "object" && data){
    if(data.objectId && !data.id){
      data.id = data.objectId;
    }
    for(let k of Object.keys(data)){
      if(Array.isArray(data[k])){
        data[k] = data[k].map(r=>{
          if(typeof r === 'object' && r)
            return convertObjectIdToId(r);
          return r;
        });
      }
      else if(typeof data[k] ==='object' && data[k] ){
        data[k] = convertObjectIdToId(data[k]);
      }else{
        data[k] = data[k];
      }
    }
    return data;
  }
  return data;
}

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    // withCredentials: false,
    // crossdomain: true,
    'Access-Control-Max-Age':1728000,
  },
});

let ele = document.getElementById("loader-container");
// Add a request interceptor
instance.interceptors.request.use(function (config) {
  ele.style.display = 'flex';
  return config;
}, function (error) {
  ele.style.display = 'none';
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (res) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  ele.style.display = 'none';

  try{
    if(res && res.data && res.data.data && Array.isArray(res.data.data.data)){
      res.data.data.data = convertObjectIdToId(res.data.data.data);
    }else if(res && res.data && res.data.data && Array.isArray(res.data.data.items)){
      
      res.data.data.items = convertObjectIdToId(res.data.data.items);
    }else if(res && res.data && Array.isArray(res.data.data)){
      
      res.data.data = convertObjectIdToId(res.data.data);
    }else if(res && res.data && Array.isArray(res.data)){
      
      res.data = convertObjectIdToId(res.data);
    }
  
    if(res && res.data && res.data.data && res.data.data.data && res.data.data.data.objectId){
      res.data.data.data  = convertObjectIdToId(res.data.data.data); //.id || res.data.data.data.objectId;
    }else if(res && res.data && res.data.data && res.data.data.objectId ){
      res.data.data  = convertObjectIdToId(res.data.data);
    } else if(res && res.data && res.data &&  res.data.objectId ){
      res.data  = convertObjectIdToId(res.data);
    }
  }catch(e){
    console.log(e, e.stack, e.trace);
  }

  return res;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // console.log(`axios.interceptors.response`, error);
  // message.error( error.message || error.toString() );
  if(error && error.response){
    if(error.response.status === 401 || error.response.statusCode === 401){
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.href = '/login';
      return;
    }
  }

  ele.style.display = 'none';
  if(error && error.response && error.response.data){
    if(error.response.data.errors){
      if(error.response.data.errors.message)
        return Promise.reject(error.response.data.errors.message);
      return Promise.reject(JSON.stringify(error.response.data.errors));
    }

    if(error.response.data.error){
      if(error.response.data.error.message)
        return Promise.reject(error.response.data.error.message);
      return Promise.reject(JSON.stringify(error.response.data.error));
    }

    if(error.response.data){
      if(error.response.data.message)
        return Promise.reject(error.response.data.message);
      return Promise.reject(JSON.stringify(error.response.data));
    }
  }
  return Promise.reject(error);
});


export default instance;