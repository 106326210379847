import React from "react";
import {Icon} from "antd";
import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login'
import {connect} from "react-redux";
import {userSignUp, socialLogin} from "../../appRedux/actions/Auth";

const facebookAppId = '721254361748100'
const APP_ROOT_URL = 'http://localhost:8002'

const googleClientId = '1055250015674-sm179ge5rdcbgj6g2cduc1b0agrfjrmd.apps.googleusercontent.com'

class SocialLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      success: false,
    };
  }

  handleFacebookResponse = (response) => {
    const params = {
      user: {
        provider: 'facebook',
        ...response,
        socialId: response.id,
        firstName: response.first_name,
        lastName: response.last_name,
      }
    }

    this.setState({
      submitting: true,
    })
    this.props.socialLogin(params,() => {
      this.setState({
        submitting: false,
      })
    });
  }

  handleGoogleResponse = (response) => {
    const params = {
      user: {
        provider: 'google',
        ...response,
        firstName: response.profileObj.givenName,
        lastName: response.profileObj.familyName,
        email: response.profileObj.email,
        socialId: response.googleId,
      }
    }

    this.setState({
      submitting: true,
    })
    this.props.socialLogin(params,() => {
      this.setState({
        submitting: false,
      })
    });
  }

  render() {
    return (
      <div className="gx-flex-row">
        <span className="gx-mb-2 gx-mr-3">or Sign up using: </span>
        <ul className="gx-social-link">
          <li>
            <FacebookLogin
              appId={facebookAppId}
              cssClass="fb-button"
              fields={'email,first_name,last_name'}
              scope="public_profile,email"
              callback={this.handleFacebookResponse}
              redirectUri={`${APP_ROOT_URL}/signin`}
              icon={<Icon type="facebook" />}
              isMobile={true}
              disableMobileRedirect={true}
              cookie={true}
              version="6.0"
              textButton=" "
              typeButton="link"
              tag="span"
            />
          </li>
          <li>
            <GoogleLogin
              clientId={googleClientId}
              render={renderProps => (
                <Icon onClick={renderProps.onClick} type="google" />
              )}
              onSuccess={this.handleGoogleResponse}
              onFailure={() => {}}
              cookiePolicy={'single_host_origin'}
            />
            {/*<Icon type="google" onClick={() => {*/}
            {/*  this.props.showAuthLoader();*/}
            {/*  this.props.userGoogleSignIn();*/}
            {/*}}/>*/}
          </li>
        </ul>
      </div>
    );
  }
}

export default connect(null, {userSignUp, socialLogin})(SocialLogin);
