/* eslint-disable */
import React from 'react';
import {message} from 'antd';
import Auxiliary from "util/Auxiliary";
import {connect} from "react-redux";

class InfoView extends React.Component {
  componentWillReceiveProps(nextProps) {
    
  }
  
  render() {
    const {error, loading} = this.props;
    return (
      <Auxiliary>
        {/* {errorMessage ? message.error(errorMessage) 
          : (successMessage ? message.success(successMessage) 
            : (infoMessage ? message.info(infoMessage) 
              : (warningMessage ? message.warn(warningMessage) : null))
          )
        } */}
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({commonData}) => {
  const {loading} = commonData;
  return {loading};
};

export default connect(mapStateToProps, {})(InfoView);
