export const openRightSider = (renderer) => {
  return (dispatch) => {
    return dispatch({
      type: 'OPEN_RIGHT_SIDER',
      payload: {
        renderer,
      },
    });
  };
};

export const closeRightSider = () => {
  return (dispatch) => {
    return dispatch({
      type: 'CLOSE_RIGHT_SIDER',
    });
  };
};

