import axios from 'util/Api';
import { LOADING_STOP, LOADING_START } from '../../constants/ActionTypes';
import { SWITCH_LANGUAGE, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH } from 'constants/ActionTypes';
import { LAYOUT_TYPE, NAV_STYLE, THEME_COLOR_SELECTION, THEME_TYPE } from '../../constants/ThemeSetting';

export function toggleCollapsedSideNav(navCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
}

export function updateWindowWidth(width) {
  return { type: WINDOW_WIDTH, width };
}

export function setThemeType(themeType) {
  return { type: THEME_TYPE, themeType };
}

export function setThemeColorSelection(colorSelection) {
  return { type: THEME_COLOR_SELECTION, colorSelection };
}

export function onNavStyleChange(navStyle) {
  return { type: NAV_STYLE, navStyle };
}

export function onLayoutTypeChange(layoutType) {
  return { type: LAYOUT_TYPE, layoutType };
}

export function switchLanguage(locale) {
  localStorage.language = JSON.stringify(locale);
  return {
    type: SWITCH_LANGUAGE,
    payload: locale,
  };
}

export const getSettingSystemCPM = (obj = {}) => {
  //params parse to get query string
  return axios.get('/admin/api/settings/adsPriceSetting', { params: obj }).then((r) => {
    return r.data;
  });
};

export const getIngredientsListApi = (obj = {}) => {
  //params parse to get query string
  return axios.get('/admin/api/settings/adsPriceSetting', { params: obj }).then((r) => {
    return r.data;
  });
};

export const updateSettingSystemCPM = (obj = {}) => {
  //params parse to get query string
  return axios.post('/admin/api/settings/adsPriceSetting', obj).then((r) => {
    return r.data;
  });
};
