/* eslint-disable */
import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon, Menu} from "antd";
import {Link, Redirect, Route} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import {USER_ROLES} from "../../constants/Roles";
import IntlMessages from "../../util/IntlMessages";

//
//
//   <Menu.Item {...props}>{}</Menu.Item>
//
//   <Route {...rest} render={props => {
//     if (!authUser) {
//       // not logged in so redirect to login page with the return url
//       return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
//     }
//
//     // check if route is restricted by role
//     if (roles && roles.indexOf(authUser.role) === -1) {
//       // role not authorised so redirect to home page
//       return <div>Unauthorized Access.</div>
//     }
//
//     // authorised so return component
//     return <Component {...props} />
//   }} />
// )

class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  renderItem = (props) => {
    const {authUser} = this.props;
    if (!authUser || (props.roles && props.roles.indexOf(authUser.role) === -1)) {
      return null
    }
    return (
      <Menu.Item key={props.key}>{props.children}</Menu.Item>
    )
  }

  render() {
    const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[0];
    let currKeySelected = defaultOpenKeys;
    // console.log(`selectedKeys`,selectedKeys );
    // console.log(`defaultOpenKeys`,defaultOpenKeys );
    let allRoles = [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.user];
    return (<Auxiliary>
        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[currKeySelected]}
              defaultSelectedKeys={[currKeySelected]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">
              {this.renderItem({
                key: "dashboard",
                roles: allRoles,
                children: <Link to="/dashboard"><i className="icon icon-dasbhoard"/><IntlMessages id="sidebar.dashboard"/></Link>
              })}
              {this.renderItem({
                key: "location",
                roles: allRoles,
                children: <Link to="/location">
                  <i className="icon icon-apps"/>Group
                </Link>
              })}
              {this.renderItem({
                key: "device",
                roles: allRoles,
                children: <Link to="/device"><Icon className="icon" type="tablet"/><IntlMessages id="sidebar.device"/></Link>
              })}
              {this.renderItem({
                key: "creative",
                children: <Link to="/creative"><Icon className="icon" type="container"/><IntlMessages id="sidebar.library"/></Link>
              })}
              {this.renderItem({
                key: "slider",
                children: <Link to="/slider"><Icon className="icon" type="camera"/>Slider</Link>
              })}
              {this.renderItem({
                key: "campaign",
                children: <Link to="/campaign"><Icon className="icon" type="carry-out"/><IntlMessages id="sidebar.campaign"/></Link>
              })}
              {this.renderItem({
                key: "user",
                roles: allRoles,
                children: <Link to="/user"><Icon className="icon" type="user"/><IntlMessages id="sidebar.user"/></Link>
              })}
              <Menu.Item key="log">
                <Link to="/log"><Icon className="icon" type="database" /><IntlMessages id="sidebar.logcenter"/></Link>
              </Menu.Item>
              {this.renderItem({
                key: "report",
                roles: allRoles,
                children: <Link to="/report"><Icon className="icon" type="area-chart"/><IntlMessages id="sidebar.report"/></Link>
              })}
              {this.renderItem({
                key: "setting",
                roles: allRoles,
                children: <Link to="/setting"><Icon className="icon" type="setting"/><IntlMessages id="sidebar.setting"/></Link>
              })}
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings, auth}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  const {authUser} = auth;
  return {navStyle, themeType, locale, pathname, authUser}
};
export default connect(mapStateToProps)(SidebarContent);

