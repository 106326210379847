export const ROLE_SUPER_ADMIN = 'SUPER_ADMIN';
export const ROLE_ADMIN = 'ADMIN';
export const ROLE_USER = 'USER';

export const USER_ROLES = {
  superAdmin: ROLE_SUPER_ADMIN,
  admin: ROLE_ADMIN,
  user: ROLE_USER,
  saler: 'SALER',
};

export const USER_TYPES = [
  {
    name: 'Customer',
    value: 3,
  },
  {
    name: 'Sale',
    value: 2,
    roles: [USER_ROLES.superAdmin, USER_ROLES.admin]
  },
  {
    name: 'Staff',
    value: 1,
    roles: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.user]
  },
];
