/* eslint-disable */
import React from "react";
import {Link, Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}sample`} component={asyncComponent(() => import('./404'))}/>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./dashboard'))}/>
      <Route path={`${match.url}agency`} component={asyncComponent(() => import('./agency'))}/>
      <Route path={`${match.url}creative`} component={asyncComponent(() => import('./creative'))}/>
      <Route path={`${match.url}slider`} component={asyncComponent(() => import('./slider'))}/>
      <Route path={`${match.url}location`} component={asyncComponent(() => import('./location'))}/>
      <Route path={`${match.url}device`} component={asyncComponent(() => import('./device'))}/>
      <Route path={`${match.url}campaign`} component={asyncComponent(() => import('./campaign'))}/>
      <Route path={`${match.url}user`} component={asyncComponent(() => import('./user'))}/>
      <Route path={`${match.url}log`} component={asyncComponent(() => import('./log'))}/>
      <Route path={`${match.url}setting`} component={asyncComponent(() => import('./setting/index'))}/>
    </Switch>
  </div>
);

export default App;
