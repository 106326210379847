import _ from 'lodash';

export default (state = {
  opened: false,
  key: _.uniqueId(),
}, action) => {
  let newState = Object.assign({}, state);

  switch (action.type) {
    case 'OPEN_RIGHT_SIDER':
      newState = Object.assign(newState, {
        opened: true,
        renderer: action.payload.renderer,
        key: _.uniqueId(),
      });

      return newState;
    case 'CLOSE_RIGHT_SIDER':
      newState = Object.assign(newState, {
        opened: false,
      });

      return newState;
    default:
      return newState;
  }
};
