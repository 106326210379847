/* eslint-disable */
import React from "react";
import IntlMessages from "util/IntlMessages";
import {Button, Checkbox, Form, Icon, Input, message} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import { forgetPasswordUser } from "../appRedux/actions/User";

const FormItem = Form.Item;

const STEP_FORGET_PASS='STEP_FORGET_PASS';
const STEP_RESET_PASS='STEP_RESET_PASS';
const STEP_SUCCESS='STEP_SUCCESS';
class ForgotPassword extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      email:'',
      otp:'',
      stepView: STEP_FORGET_PASS,
      password:'',
      rePassword:''
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if(values.password && values.password !== values.rePassword){
          message.error('Password doesnt match');
          return;
        }
        if(this.state.stepView === STEP_FORGET_PASS){
          forgetPasswordUser(values).then(r=>{
            message.success(`Your request forget password has been sent. Please check email to get otp code !`);
            if(r){
              this.setState({
                otp: '',
                email: values.email,
                stepView: STEP_RESET_PASS
              });
            }
          }).catch(e=>{
            message.error(e);
          })
        }else if(this.state.stepView === STEP_RESET_PASS){
          forgetPasswordUser(values).then(r=>{
            message.success(`Your password has been reset. You can login now!`);
            if(r && r.data){
              this.setState({
                stepView: STEP_SUCCESS,
                otp: '',
                email: '',
              });
            }
          }).catch(e=>{
            message.error(e);
          })
        }
      }
    });
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    let {otp, email, password, rePassword, stepView} = this.state;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div>
            <div className="gx-login-container">
              <div className="gx-login-content">
                <div className="gx-login-header">
                  <img alt="" src={require("assets/images/logo.png")}/>
                </div>
                <div className="gx-mb-4">
                  <h2>Forgot Your Password ?</h2>
                  <p><IntlMessages id="app.userAuth.forgot"/></p>
                </div>
                <Form layout="vertical" onSubmit={this.handleSubmit} className="gx-login-form gx-form-row0">
                  {this.state.stepView ===STEP_SUCCESS ? <div className="text-primary">Your password has been reset successfully. Please login now.</div> : null}
                  <FormItem label="Email">
                    {getFieldDecorator('email', {
                      rules: [{
                        type: 'email', message: 'The input is not valid E-mail!',
                      }, {
                        required: true, message: 'Please input your E-mail!',
                      }],
                      initialValue: email,
                      disabled: this.state.stepView === STEP_RESET_PASS
                    })(
                      <Input type="email" placeholder="E-Mail Address" disabled={this.state.stepView === STEP_RESET_PASS} />
                    )}
                  </FormItem>

                  {stepView === STEP_RESET_PASS ? 
                  <FormItem label="OTP" label="OTP" extra={<div className="text-small small text-info">Please check email to get OTP.</div>}>
                    {getFieldDecorator('otp', {
                      rules: [{
                        required: true, message: 'Please check your email and input otp.',
                      }],
                      initialValue: otp,
                    })(
                      <Input type="text" placeholder="OTP" />
                    )}
                  </FormItem> : null}

                  {stepView === STEP_RESET_PASS ? 
                  <FormItem label="Password" title="Password">
                    {getFieldDecorator('password', {
                      rules: [{
                        required: true, message: 'Please input your password',
                      }],
                      initialValue: password,
                    })(
                      <Input type="password" placeholder="Password"/>
                    )}
                  </FormItem> : null}

                  {stepView === STEP_RESET_PASS ? 
                  <FormItem label="Re-Password" title="Re-Password">
                    {getFieldDecorator('rePassword', {
                      rules: [{
                        required: true, message: 'Please input your re-password',
                      }],
                      initialValue: rePassword,
                    })(
                      <Input type="password" placeholder="Re-Password"/>
                    )}
                  </FormItem> : null}
                  <FormItem>
                    <Button type="primary" className="gx-mb-0" htmlType="submit">
                      <IntlMessages id="app.userAuth.send"/>
                    </Button>
                    <span><IntlMessages id="app.userAuth.or"/> </span>  
                    <Link to="/signin"><IntlMessages id="app.userAuth.signIn"/></Link>
                  </FormItem>
                </Form>
                
              </div>
            </div>
      </div></div></div>
    );
  }
}

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);
const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};
export default connect(mapStateToProps, {})(WrappedForgotPasswordForm);
