let API_URL = process.env.REACT_APP_ENV==='production' ? 'https://api.dikauri.com/' : 'https://tabletopapi.dikauri.com/';
console.log(`%c env app started`,'background:green; font-size:20px; color:white; padding:10px',{
  'NODE_ENV':process.env.NODE_ENV,
  'REACT_APP_ENV':process.env.REACT_APP_ENV,
  'API_URL':API_URL,
});


module.exports = {
  cmsVersion: 'v1.0.2',
  footerText: '© Copyright 2020 Digital Kauri. All rights reserved.',
  googleApiKey: 'AIzaSyBuNPW0PjmCo_r6TncEfLR5frWkItj1XeI',
  API_URL: API_URL,
}
