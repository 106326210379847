/* eslint-disable */
import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import IntlMessages from "util/IntlMessages";
import {userSignOut} from "appRedux/actions/Auth";
import {switchLanguage, toggleCollapsedSideNav} from "../../appRedux/actions";
import {withRouter} from "react-router-dom";

class UserInfo extends Component {

  render() {
    const avatar = this.props.authUser && this.props.authUser.avatar ? this.props.authUser.avatar : 'https://wieldy.g-axon.work/static/media/garry-sobars.aa4d3b15.png';
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => {this.props.history.push(`/user/me`)}}><IntlMessages id="app.userAuth.myProfile"/></li>
        <li onClick={() => this.props.userSignOut()}><IntlMessages id="app.userAuth.logout"/></li>
      </ul>
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={avatar} className="gx-avatar gx-pointer top-nav-user-avatar" alt=""/>
      </Popover>
    )
  }
}
const mapStateToProps = ({auth, settings}) => {
  const {authUser} = auth;
  const {locale} = settings;
  return {authUser, locale}
};
export default connect(mapStateToProps, {userSignOut})(withRouter(UserInfo));
