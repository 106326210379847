import axios from "util/Api";

export const getAuthUser = () => {
  try {
    if (localStorage.getItem("user")) {
      return JSON.parse(localStorage.getItem("user"));
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const uploadAvatarUser = file => {
  let formData = new FormData();
  formData.append("file", file);
  return axios
    .post("api/files/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(r => r.data);
};

// No route admin
export const forgetPasswordUser = params => {
  return axios
    .post("/api/users/forget-password", { ...params })
    .then(r => r.data);
};

// No route admin
export const updatePasswordAuthUser = params => {
  return axios
    .post("api/users/change-password", { ...params, objectId: params.id })
    .then(r => r.data);
};

export const updatePasswordByAdmin = params => {
  return axios
    .post("/admin/api/users/change-password", {
      ...params,
      objectId: params.id
    })
    .then(r => r.data);
};

export const getListUser = params => {
  return axios.post("/admin/api/users/search", params).then(r => r.data);
};

export const getUserDetail = params => {
  return axios
    .post("/admin/api/users/detail", { ...params, objectId: params.id })
    .then(r => r.data);
};

export const verify = params => {
  return axios.post("/admin/api/users/verify", params).then(r => r.data);
};

export const resendEmail = params => {
  return axios.post("/admin/api/users/resendEmail", params).then(r => r.data);
};

export const updateStatus = params => {
  return axios.post("/admin/api/users/update-status", params).then(r => r.data);
};
export const updateUserStatus = updateStatus;

export const updateType = params => {
  return axios.post("/admin/api/users/update-type", params).then(r => r.data);
};

export const update = params => {
  return axios.post("/admin/api/users/update", params).then(r => r.data);
};

export const updateInfoUser = params => {
  return axios.post("/admin/api/users/update", params).then(r => r.data);
};

export const getActivity = params => {
  return axios.post("/admin/api/activity/user", params).then(r => r.data);
};

export const createUserByAdmin = params => {
  return axios.post("/admin/api/users/create", params).then(r => r.data);
};
